import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <p>An M or F appearing at the end of a spell&rsquo;s name in the
spell
lists denotes a spell with a material or focus component, respectively,
that is not normally included in a spell component pouch. An X denotes
a spell with an XP component paid by the caster.</p>
    <a id="order-of-presentation"></a><p><b>Order of Presentation</b>: In the spell lists and the spell
descriptions that follow them, the spells are presented in alphabetical
order by name except for those belonging to certain spell chains.</p>
    <p>When a spell&rsquo;s name begins with &ldquo;lesser,&rdquo;
&ldquo;greater,&rdquo; or
&ldquo;mass,&rdquo; the spell description is alphabetized under the
second word of
the spell name instead.</p>
    <a id="hit-dice"></a><p><b>Hit Dice</b>: The term &ldquo;Hit Dice&rdquo; is used
synonymously with
&ldquo;character levels&rdquo; for effects that affect a number of Hit
Dice of
creatures. Creatures with Hit Dice only from their race, not from
classes, have character levels equal to their Hit Dice.</p>
    <a id="caster-level"></a><p><b>Caster Level</b>: A spell&rsquo;s power often depends on
caster
level, which is defined as the caster&rsquo;s class level for the
purpose of
casting a particular spell. A creature with no classes has a caster
level equal to its Hit Dice unless otherwise specified. The word
&ldquo;level&rdquo; in the spell lists that follow always refers to
caster level.</p>
    <a id="creatures-and-characters"></a><p><b>Creatures and Characters</b>: The words
&ldquo;creature&rdquo; and
&ldquo;character&rdquo; are used synonymously in the spell descriptions.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      